import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'
import { getOperatingSystemVersion } from 'helpers/getOperatingSystemVersion'
import { store } from 'root-redux/store'

export const getCommonEventProps = (utmTags: Record<string, string>) => {
  const state = store.getState()

  return {
    is_prod_env: getCurrentEnv() === 'prod',
    cohort: state.common.currentVariant?.cohort,
    lang: state.common.language,
    device_type: getMobileOperatingSystem(),
    operating_system_version: getOperatingSystemVersion(),
    attribution: utmTags,
    viewport_width: window.screen.width,
    viewport_height: window.screen.height,
    viewport_size: `${window.screen.width}x${window.screen.height}`,
    webapp_redirect: state.common.isWebAppFunnel,
  }
}
