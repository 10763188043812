import { TAnswers } from 'models/common.model'
import { NONE } from 'root-constants'

export const getCommandsAnswers = (
  answers: Partial<TAnswers> | null,
): string[] =>
  answers
    ? Object.entries(answers).reduce(
        (acc, [pageId, answer]) =>
          pageId.startsWith('command') && answer !== NONE
            ? [...acc, ...answer]
            : acc,
        [] as string[],
      )
    : []
