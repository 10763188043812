import { TDynamicPageComponent } from 'models/common.model'
import React, { useEffect, useMemo } from 'react'
import { PageId } from 'constants/pages'
import slugDog from 'assets/images/terrier-slug-dog.jpg'
import { Trans, useTranslation } from 'react-i18next'
import { LANG_QUERY_PARAM } from 'root-constants'
import { useLocation } from 'react-router'
import { StyledPage as S } from './styles'

const Component: TDynamicPageComponent = () => {
  const { t, i18n } = useTranslation()
  const { search } = useLocation()

  const langQueryParam = useMemo(() => {
    const URLParams = new URLSearchParams(search)

    return URLParams.get(LANG_QUERY_PARAM) || ''
  }, [search])

  useEffect(() => {
    i18n.changeLanguage(langQueryParam)
  }, [i18n, langQueryParam])

  return (
    <S.SlugWrapper>
      <S.Content>
        <S.XmasDog src={slugDog} alt="funny dog" />
        <S.SlugNote>{t`slug.note`}</S.SlugNote>
        <S.CloseNote>
          <Trans i18nKey="slug.closeInstructions" components={[<strong />]} />
        </S.CloseNote>
      </S.Content>
    </S.SlugWrapper>
  )
}

Component.id = PageId.VC_SLUG

export const VideoCoachingSlug = Component
