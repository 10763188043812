/* eslint-disable max-lines */
export const enum PageId {
  INTRO_1 = 'intro_1',
  WELCOME_1 = 'welcome_1',
  WELCOME_2 = 'welcome_2',
  WELCOME_3 = 'welcome_3',
  WELCOME_TEST_22_V2 = 'welcome_test_22_v2',
  SHORT_WELCOME_3 = 'short_welcome_3',
  DOG_NAME_1 = 'dog_name_1',
  DOG_NAME_HOG = 'dog_name_hog',
  DOG_NAME_RT = 'dog_name_rt',
  DOG_AGE_3 = 'dog_age_3',
  DOG_AGE_4 = 'dog_age_4',
  DOG_AGE_4_V2 = 'dog_age_4_v2',
  DOG_AGE_5 = 'dog_age_5',
  DOG_AGE_VC = 'dog_age_vc',
  DOG_AGE_VC_2 = 'dog_age_vc_2',
  DOG_AGE_PF = 'dog_age_pf',
  DOG_AGE_S1 = 'dog_age_s1',
  DOG_AGE_PT = 'dog_age_pt',
  DOG_AGE_HOG = 'dog_age_hog',
  DOG_AGE_RT = 'dog_age_rt',
  DOG_AGE_RFND = 'dog_age_rfnd',
  DOG_BREED_6 = 'dog_breed_6',
  GOALS_QUESTION_2 = 'goals_question_2',
  GOALS_QUESTION_4 = 'goals_question_4',
  GOALS_QUESTION_5 = 'goals_question_5',
  COMMANDS_2 = 'commands_2',
  COMMANDS_4 = 'commands_4',
  COMMANDS_5 = 'commands_5',
  COMMANDS_61 = 'commands_61',
  COMMANDS_61_RFND = 'commands_61_rfnd',
  COMMANDS_62 = 'commands_62',
  COMMANDS_62_RFND = 'commands_62_rfnd',
  COMMANDS_63 = 'commands_63',
  COMMANDS_63_RFND = 'commands_63_rfnd',
  COMMANDS_HOG = 'commands_hog',
  COMMANDS_RT = 'commands_rt',
  TIME_3 = 'time_3',
  TIME_4 = 'time_4',
  PREPARE_FLOW_4 = 'prepare_flow_4',
  PREPARE_FLOW_5 = 'prepare_flow_5',
  PREPARE_FLOW_6 = 'prepare_flow_6',
  PREPARE_FLOW_7 = 'prepare_flow_7',
  PREPARE_FLOW_8 = 'prepare_flow_8',
  PREPARE_FLOW_PT = 'prepare_flow_pt',
  PREPARE_FLOW_HOG = 'prepare_flow_hog',
  PREPARE_FLOW_RT = 'prepare_flow_rt',
  PREPARE_FLOW_RFND = 'prepare_flow_rfnd',
  EMAIL_6 = 'email_6',
  EMAIL_7 = 'email_7',
  EMAIL_8 = 'email_8',
  EMAIL_9 = 'email_9',
  EMAIL_10 = 'email_10',
  EMAIL_11 = 'email_11',
  EMAIL_12 = 'email_12',
  EMAIL_12_V2 = 'email_12_v2',
  EMAIL_VC = 'email_vc',
  EMAIL_PF = 'email_pf',
  EMAIL_PT = 'email_pt',
  EMAIL_HOG = 'email_hog',
  EMAIL_RT = 'email_rt',
  EMAIL_RFND = 'email_rfnd',
  SUBSCRIPTIONS_5 = 'subscriptions_5',
  SUBSCRIPTIONS_5_V2 = 'subscriptions_5_v2',
  SUBSCRIPTIONS_5_V3 = 'subscriptions_5_v3',
  SUBSCRIPTIONS_6 = 'subscriptions_6',
  SUBSCRIPTIONS_6_V2 = 'subscriptions_6_v2',
  SUBSCRIPTIONS_6_V3 = 'subscriptions_6_v3',
  SUBSCRIPTIONS_7 = 'subscriptions_7',
  SUBSCRIPTIONS_8 = 'subscriptions_8',
  SUBSCRIPTIONS_9 = 'subscriptions_9',
  SUBSCRIPTIONS_10 = 'subscriptions_10',
  SUBSCRIPTIONS_11 = 'subscriptions_11',
  SUBSCRIPTIONS_12 = 'subscriptions_12',
  SUBSCRIPTIONS_13 = 'subscriptions_13',
  SUBSCRIPTIONS_14 = 'subscriptions_14',
  SUBSCRIPTIONS_15 = 'subscriptions_15',
  SUBSCRIPTIONS_15_V2 = 'subscriptions_15_v2',
  SUBSCRIPTIONS_15_V3 = 'subscriptions_15_v3',
  SUBSCRIPTIONS_15_V4 = 'subscriptions_15_v4',
  SUBSCRIPTIONS_15_V5 = 'subscriptions_15_v5',
  SUBSCRIPTIONS_16 = 'subscriptions_16',
  SUBSCRIPTIONS_16_V3 = 'subscriptions_16_v3',
  SUBSCRIPTIONS_17 = 'subscriptions_17',
  SUBSCRIPTIONS_18 = 'subscriptions_18',
  SUBSCRIPTIONS_PT = 'subscriptions_pt',
  SUBSCRIPTIONS_HOG = 'subscriptions_hog',
  SUBSCRIPTIONS_BF = 'subscriptions_bf',
  SUBSCRIPTIONS_BF_V2 = 'subscriptions_bf_v2',
  SUBSCRIPTIONS_BF_V3 = 'subscriptions_bf_v3',
  SUBSCRIPTIONS_BF_V4 = 'subscriptions_bf_v4',
  SUBSCRIPTIONS_RT = 'subscriptions_rt',
  SUBSCRIPTIONS_RFND = 'subscriptions_rfnd',
  SUBSCRIPTIONS_PAID_TRIAL_1P1 = 'subscriptions_paid_trial_1p1',
  SUBSCRIPTIONS_PAID_TRIAL_1P3 = 'subscriptions_paid_trial_1p3',
  SUBSCRIPTIONS_PAID_TRIAL_1P4 = 'subscriptions_paid_trial_1p4',
  // SUBSCRIPTIONS_TRIAL_PRICE_1 = 'subscriptions_trial_price_1',
  SUBSCRIPTIONS_TRIAL_PRICE = 'subscriptions_trial_price',
  SUBSCRIPTIONS_TRIAL_PRICE_2 = 'subscriptions_trial_price_2',
  SUBSCRIPTIONS_TRIAL_PRICE_2_V2 = 'subscriptions_trial_price_2_v2',
  SUBSCRIPTIONS_SECONDARY_VC = 'subscriptions_secondary_vc',
  SUBSCRIPTIONS_TRIAL_PRICE_3 = 'subscriptions_trial_price_3',
  SUBSCRIPTIONS_TRIAL_PRICE_3_V2 = 'subscriptions_trial_price_3_v2',
  SUBSCRIPTIONS_WEBAPP = 'subscriptions_webapp',
  SUBSCRIPTIONS_XMAS = 'subscriptions_xmas',
  SUBSCRIPTIONS_XMAS_3 = 'subscriptions_xmas_3',
  SUBSCRIPTIONS_VDAY = 'subscriptions_vday',
  SUBSCRIPTIONS_VDAY_2 = 'subscriptions_vday_2',
  SUBSCRIPTIONS_VDAY_3 = 'subscriptions_vday_3',
  SUBSCRIPTIONS_VDAY_4 = 'subscriptions_vday_4',
  SUBSCRIPTIONS_PUPPY_DAY_65 = 'subscriptions_puppy_day_65',
  SUBSCRIPTIONS_PUPPY_DAY_70 = 'subscriptions_puppy_day_70',
  SUBSCRIPTIONS_PUPPY_DAY_75 = 'subscriptions_puppy_day_75',
  SUBSCRIPTIONS_PUPPY_DAY_80 = 'subscriptions_puppy_day_80',
  CANCEL_OFFER_1 = 'cancel_offer_1',
  CANCEL_OFFER_2 = 'cancel_offer_2',
  CANCEL_OFFER_3 = 'cancel_offer_3',
  CANCEL_OFFER_4 = 'cancel_offer_4',
  CANCEL_OFFER_4_V2 = 'cancel_offer_4_v2',
  CANCEL_OFFER_4_V3 = 'cancel_offer_4_v3',
  CANCEL_OFFER_4_V4 = 'cancel_offer_4_v4',
  CANCEL_OFFER_4_V5 = 'cancel_offer_4_v5',
  CANCEL_OFFER_5 = 'cancel_offer_5',
  CANCEL_OFFER_5_V3 = 'cancel_offer_5_v3',
  CANCEL_OFFER_PF = 'cancel_offer_pf',
  CANCEL_OFFER_PT = 'cancel_offer_pt',
  CANCEL_OFFER_HOG = 'cancel_offer_hog',
  CANCEL_OFFER_WEBAPP = 'cancel_offer_webapp',
  CANCEL_OFFER_RT = 'cancel_offer_rt',
  CANCEL_OFFER_RFND = 'cancel_offer_rfnd',
  PAYMENT_3 = 'payment_3',
  PAYMENT_4 = 'payment_4',
  PAYMENT_5 = 'payment_5',
  PAYMENT_6 = 'payment_6',
  PAYMENT_7 = 'payment_7',
  PAYMENT_8 = 'payment_8',
  PAYMENT_9 = 'payment_9',
  PAYMENT_9_V2 = 'payment_9_v2',
  PAYMENT_10 = 'payment_10',
  PAYMENT_10_VC = 'payment_10_vc',
  PAYMENT_11 = 'payment_11',
  PAYMENT_12 = 'payment_12',
  PAYMENT_12_V2 = 'payment_12_v2',
  PAYMENT_12_V3 = 'payment_12_v3',
  PAYMENT_13 = 'payment_13',
  PAYMENT_WITHOUT_PAYPAL = 'payment_without_paypal',
  PAYMENT_PT = 'payment_pt',
  PAYMENT_HOG = 'payment_hog',
  PAYMENT_WEBAPP = 'payment_webapp',
  PAYMENT_BF = 'payment_bf',
  PAYMENT_RT = 'payment_rt',
  PAYMENT_RFND = 'payment_rfnd',
  ACCOUNT = 'account',
  ACCOUNT_2 = 'account_2',
  DOWNLOAD = 'download',
  DOWNLOAD_2 = 'download_2',
  WEB_APP_REDIRECT = 'web_app_redirect',
  WEB_APP_REDIRECT_PDF = 'web_app_redirect_pdf',
  URGE_1 = 'urge_1',
  URGE_4 = 'urge_4',
  URGE_5 = 'urge_5',
  URGE_5_V2 = 'urge_5_v2',
  URGE_6 = 'urge_6',
  URGE_7 = 'urge_7',
  URGE_VC = 'urge_vc',
  URGE_VC_2 = 'urge_vc_2',
  URGE_PF = 'urge_pf',
  URGE_PF_V2 = 'urge_pf_v2',
  URGE_PF_V3 = 'urge_pf_v3',
  URGE_PT = 'urge_pt',
  URGE_PT_2 = 'urge_pt_2',
  URGE_HOG = 'urge_hog',
  URGE_HOG_2 = 'urge_hog_2',
  URGE_RT = 'urge_rt',
  URGE_RT_2 = 'urge_rt_2',
  URGE_RFND = 'urge_rfnd',
  URGE_RFND_2 = 'urge_rfnd_2',
  URGE_TEST_22_V4 = 'urge_test_22_v4',
  EXPERT_HELP = 'expert_help',
  ALMOST_DONE_VC = 'almost_done_vc',
  ALMOST_DONE_VC_2 = 'almost_done_vc_2',
  TRAINER_IS_READY_VC = 'trainer_is_ready_vc',
  TRAINER_IS_READY_VC_2 = 'trainer_is_ready_vc_2',
  OUR_MISSION_VC = 'our_mission_vc',
  OUR_MISSION_VC_2 = 'our_mission_vc_2',
  OUR_MISSION_VC_3 = 'our_mission_vc_3',
  DOG_WEIGHT_PF = 'dog_weight_pf',
  DOG_BODY_CONDITION_PF = 'dog_body_condition_pf',
  DOG_ENERGY_PF = 'dog_energy_pf',
  DOG_NEUTERED_PF = 'dog_neutered_pf',
  DOG_FEED_PF = 'dog_feed_pf',
  DOG_SHARE_FOOD_PF = 'dog_share_food_pf',
  ANXIOUS_3 = 'anxious_3',
  STRESS_3 = 'stress_3',
  STRESS_VC = 'stress_vc',
  FEED_2 = 'feed_2',
  FEED_VC = 'feed_vc',
  FEED_TABLE_2 = 'feed_table_2',
  HOME_ALONG_3 = 'home_along_3',
  HOME_ALONG_4 = 'home_along_4',
  HOME_ALONG_VC = 'home_along_vc',
  GUARD_2 = 'guard_2',
  HYGIENE_3 = 'hygiene_3',
  ACTIVITY_2 = 'activity_2',
  ACTIVITY_VC = 'activity_vc',
  ACTIVITY_VC_2 = 'activity_vc_2',
  DESCRIBE_2 = 'describe_2',
  DESCRIBE_VC = 'describe_vc',
  DESCRIBE_VC_2 = 'describe_vc_2',
  REGRET_2 = 'regret_2',
  BEHAVIOR_2 = 'behavior_2',
  BEHAVIOR_3 = 'behavior_3',
  BEHAVIOR_VC = 'behavior_vc',
  BEHAVIOR_HOG = 'behavior_hog',
  BEHAVIOR_RT = 'behavior_rt',
  BEHAVIOR_RFND = 'behavior_rfnd',
  RESULT_2 = 'result_2',
  SEX_2 = 'sex_2',
  SEX_3 = 'sex_3',
  SEX_4 = 'sex_4',
  SEX_4_V2 = 'sex_4_v2',
  SEX_5 = 'sex_5',
  SEX_VC = 'sex_vc',
  SEX_PF = 'sex_pf',
  SEX_PT = 'sex_pt',
  SEX_HOG = 'sex_hog',
  SEX_RT = 'sex_rt',
  SEX_RFND = 'sex_rfnd',
  DOG_AGE_2 = 'dog_age_2',
  DOG_BREED_1 = 'dog_breed_1',
  DOG_BREED_4 = 'dog_breed_4',
  DOG_BREED_5 = 'dog_breed_5',
  DOG_BREED_7 = 'dog_breed_7',
  DOG_BREED_VC = 'dog_breed_vc',
  DOG_BREED_PF = 'dog_breed_pf',
  DOG_BREED_S1 = 'dog_breed_s1',
  DOG_BREED_PT = 'dog_breed_pt',
  DOG_BREED_HOG = 'dog_breed_hog',
  DOG_BREED_RT = 'dog_breed_rt',
  DOG_BREED_RFND = 'dog_breed_rfnd',
  COMMANDS_3 = 'commands_3',
  URGE_3 = 'urge_3',
  URGE_2 = 'urge_2',
  NORMAL_FEELINGS = 'normal_feelings',
  DIFFICULTY_URGE = 'difficulty_urge',
  NERVOUS_2 = 'nervous_2',
  ANXIOUS_2 = 'anxious_2',
  STRESS_2 = 'stress_2',
  HOME_ALONG_2 = 'home_along_2',
  WALK_2 = 'walk_2',
  WALK_3 = 'walk_3',
  WALK_4 = 'walk_4',
  WALK_VC = 'walk_vc',
  HYGIENE_2 = 'hygiene_2',
  POINTS_OF_CONCERN_3 = 'points_of_concern_3',
  POINTS_OF_CONCERN_4 = 'points_of_concern_4',
  POINTS_OF_CONCERN_5 = 'points_of_concern_5',
  POINTS_OF_CONCERN_VC = 'points_of_concern_vc',
  PERILOUS_SITUATIONS_1 = 'perilous_situations_1',
  FOOD_1 = 'food_1',
  FOOD_2 = 'food_2',
  FOOD_MOTIVATED_PF = 'food_motivated_pf',
  AFRAID_1 = 'afraid_1',
  AFRAID_2 = 'afraid_2',
  COMMAND_STAY_1 = 'command_stay_1',
  COMMAND_STAY_2 = 'command_stay_2',
  COMMAND_LEAVE_IT_1 = 'command_leave_it_1',
  COMMAND_LEAVE_IT_2 = 'command_leave_it_2',
  COME_WHEN_CALLED_1 = 'come_when_called_1',
  ANNOYED_1 = 'annoyed_1',
  ANNOYED_2 = 'annoyed_2',
  HEALTHY_BOUND_1 = 'healthy_bound_1',
  HEALTHY_BOUND_2 = 'healthy_bound_2',
  TRAINING_2 = 'training_2',
  TRAINING_3 = 'training_3',
  TRAINING_4 = 'training_4',
  TRAINING_5 = 'training_5',
  TRAINING_RFND = 'training_rfnd',
  GOALS_QUESTION_3 = 'goals_question_3',
  GOALS_QUESTION_VC = 'goals_question_vc',
  GOALS_QUESTION_VC_2 = 'goals_question_vc_2',
  TIME_2 = 'time_2',
  PREPARE_FLOW_3 = 'prepare_flow_3',
  EMAIL_5 = 'email_5',
  NON_PAYER_QUESTION_2 = 'non_payer_question_2',
  NON_PAYER_INFO_2 = 'non_payer_info_2',
  NERVOUS_3 = 'nervous_3',
  LONELY_1 = 'lonely_1',
  UPSELL_1 = 'upsell_1',
  CHAT_UPSELL = 'chat_upsell',
  HEALTH_ISSUES_VC = 'health_issues_vc',
  HEALTH_ISSUES_VC_2 = 'health_issues_vc_2',
  HEALTH_ISSUES = 'health_issues',
  DOG_SUMMARY = 'dog_summary',
  FEATURE_DESCRIPTION = 'feature_description',
  FEATURE_DESCRIPTION_2 = 'feature_description_2',
  CYNOLOGISTS = 'cynologists',
  CYNOLOGISTS_VC_2 = 'cynologists_vc_2',
  LIMITED_OFFER = 'limited_offer',
  BOOK_SESSION = 'book_session',
  N_DAY_TRIAL = 'n_day_trial',
  USER_PHONE = 'user_phone',
  CYNOLOGIST_CONTACT = 'cynologist_contact',
  ALL_SET_VC = 'all_set_vc',
  CALENDLY = 'calendly',
  CALENDLY_2 = 'calendly_2',
  CALENDLY_3 = 'calendly_3',
  LAST_CHANCE_OFFER_2 = 'last_chance_offer_2',
  LAST_CHANCE_OFFER_2_V2 = 'last_chance_offer_2_v2',
  LAST_CHANCE_OFFER_3 = 'last_chance_offer_3',
  LAST_CHANCE_OFFER_4 = 'last_chance_offer_4',
  LAST_CHANCE_OFFER_4_V2 = 'last_chance_offer_4_v2',
  LAST_CHANCE_OFFER_4_V3 = 'last_chance_offer_4_v3',
  LAST_CHANCE_OFFER_PF = 'last_chance_offer_pf',
  LAST_CHANCE_OFFER_PT = 'last_chance_offer_pt',
  LAST_CHANCE_OFFER_D70 = 'last_chance_offer_d70',
  LAST_CHANCE_OFFER_D70_V2 = 'last_chance_offer_d70_v2',
  LAST_CHANCE_OFFER_D80 = 'last_chance_offer_d80',
  LAST_CHANCE_OFFER_D80_V2 = 'last_chance_offer_d80_v2',
  LAST_CHANCE_OFFER_5 = 'last_chance_offer_5',
  LAST_CHANCE_OFFER_5_V2 = 'last_chance_offer_5_v2',
  LAST_CHANCE_OFFER_RT = 'last_chance_offer_rt',
  LAST_CHANCE_OFFER_RFND = 'last_chance_offer_rfnd',
  LAST_CHANCE_OFFER_RFND_2 = 'last_chance_offer_rfnd_2',
  SHELTER_1 = 'shelter_1',
  SHELTER_VC = 'shelter_vc',
  SHELTER_S1 = 'shelter_s1',
  SHELTER_PT = 'shelter_pt',
  DANGEROUS_SITUATION = 'dangerous_situation',
  TRUST_ISSUES = 'trust_issues',
  RELATED_ISSUES = 'related_issues',
  PETS_AND_CHILDREN = 'pets_and_children',
  NEW_PLACE = 'new_place',
  MEETING_STRANGERS = 'meeting_strangers',
  TRAINING_DIFFICULTIES = 'training_difficulties',
  DID_YOU_KNOW = 'did_you_know',
  STARTED_PT = 'started_pt',
  PLACE_PT = 'place_pt',
  GOALS_PT = 'goals_pt',
  ACCIDENTS_PT = 'accidents_pt',
  CONCERNS_PT = 'concerns_pt',
  LIVING_SITUATION = 'living_situation',
  POTTY_BREAKS = 'potty_breaks',
  CRATE_TRAINING = 'crate_training',
  TRAINING_TIME = 'training_time',
  DOG_NEUTERED_HOG = 'dog_neutered_hog',
  DOG_NEUTERED_RT = 'dog_neutered_rt',
  DOG_IMPROVE = 'dog_improve',
  DOG_BEG_FOOD = 'dog_beg_food',
  DOG_BEG_FOOD_RT = 'dog_beg_food_rt',
  OBEDIENCE = 'obedience',
  REFUSE_ATTENTION = 'refuse_attention',
  DOG_MISBEHAVE = 'dog_misbehave',
  RUDE_TO_DOG = 'rude_to_dog',
  DOG_LISTEN = 'dog_listen',
  FRUSTRATED = 'frustrated',
  FRUSTRATED_RT = 'frustrated_rt',
  VISITING_PLACES = 'visiting_places',
  DOG_DESCRIBE = 'dog_describe',
  DOG_RELATION = 'dog_relation',
  BOND_KNOWLEDGE = 'bond_knowledge',
  EVENTS = 'events',
  EVENTS_RT = 'events_rt',
  EVENTS_DATE = 'events_date',
  EVENTS_DATE_RT = 'events_date_rt',
  FIX_BEHAVIOR = 'fix_behavior',
  FIX_BEHAVIOR_RT = 'fix_behavior_rt',
  POTTY_1 = 'potty_1',
  POTTY_RFND = 'potty_rfnd',
  DOG_STATISTICS = 'dog_statistics',
  DOG_STATISTICS_HOG = 'dog_statistics_hog',
  DOG_STATISTICS_RT = 'dog_statistics_rt',
  DOG_STATISTICS_RFND = 'dog_statistics_rfnd',
  DOG_IGNORED = 'dog_ignored',
  DOG_IGNORED_RT = 'dog_ignored_rt',
  DOG_SOCIALIZATION = 'dog_socialization',
  STRESS_SITUATIONS = 'stress_situations',
  DOG_FRIENDLY = 'dog_friendly',
  DOG_HANDLER = 'dog_handler',
  DOG_HANDLER_RT = 'dog_handler_rt',
  TRAINING_HOG = 'training_hog',
  TRAINING_RT = 'training_rt',
  BEHAVIOR_SATISFIED = 'behavior_satisfied',
  REACTIVITY_UNDERSTANDING = 'reactivity_understanding',
  DOG_REACTIVE = 'dog_reactive',
  DOG_OVERREACT = 'dog_overreact',
  GOALS_RT = 'goals_rt',
  DOG_REACT = 'dog_react',
  DOG_DISCIPLINE = 'dog_discipline',
  DOG_EXCITEMENT_TRIGGERS = 'dog_excitements_triggers',
  DOG_AGRESSIVE = 'dog_agressive',
  DOG_AGRESSION_TRIGGERS = 'dog_agression_triggers',
  DOG_SCARED = 'dog_scared',
  DOG_FEAR_TRIGGERS = 'dog_fear_triggers',
  OTHER_DOGS_REACTION = 'other_dogs_reaction',

  // invoxia
  UPSELL_INVOXIA = 'upsell_invoxia',
  INVOXIA_CONGRATULATIONS = 'invoxia_congratulations',
  INVOXIA_REVIEW = 'invoxia_review',
  INVOXIA_DETAILS = 'invoxia_details',

  // fashion pages
  WELCOME_FASHION = 'welcome_fashion',
  LIFESTYLE_FASHION = 'lifestyle_fashion',
  REASON_FASHION = 'reason_fashion',
  STYLE_GOAL_FASHION = 'style_goal_fashion',
  AGE_FASHION = 'age_fashion',
  URGE_FASHION = 'urge_fashion',
  URGE_FASHION_2 = 'urge_fashion_2',
  URGE_FASHION_3 = 'urge_fashion_3',
  HAIR_LENGHT_FASHION = 'hair_lenght_fashion',
  HAIR_COLOR_FASHION = 'hair_color_fashion',
  EYES_COLOR_FASHION = 'eyes_color_fashion',
  BODY_SHAPE_FASHION = 'body_shape_fashion',
  SKIN_COLOR_FASHION = 'skin_color_fashion',
  TALL_FASHION = 'tall_fashion',
  WEIGHT_FASHION = 'weight_fashion',
  SHOPPING_FASHION = 'shopping_fashion',
  FIND_NEW_CLOTHES_FASHION = 'find_new_clothes_fashion',
  NOTHING_TO_WEAR_FASHION = 'nothing_to_wear_fashion',
  OLD_CLOTHES_FASHION = 'old_clothes_fashion',
  COMBINE_CLOTHES_FASHION = 'combine_clothes_fashion',
  STYLE_MOST_MATTERS_FASHION = 'style_most_matters_fashion',
  FAVORITE_COLORS_FASHION = 'favorite_colors_fashion',
  LIKE_OR_DISLIKE_1 = 'like_or_dislike_1',
  LIKE_OR_DISLIKE_2 = 'like_or_dislike_2',
  LIKE_OR_DISLIKE_3 = 'like_or_dislike_3',
  LIKE_OR_DISLIKE_4 = 'like_or_dislike_4',
  LIKE_OR_DISLIKE_5 = 'like_or_dislike_5',
  LIKE_OR_DISLIKE_6 = 'like_or_dislike_6',
  LIKE_OR_DISLIKE_7 = 'like_or_dislike_7',
  LIKE_OR_DISLIKE_8 = 'like_or_dislike_8',
  LIKE_OR_DISLIKE_9 = 'like_or_dislike_9',
  LIKE_OR_DISLIKE_10 = 'like_or_dislike_10',
  OPTIMIZE_BUDGET_FASHION = 'optimize_budget_fashion',
  PREPARE_FLOW_FASHION = 'prepare_flow_fashion',
  EMAIL_FASHION = 'email_fashion',
  SUBSCRIPTIONS_FASHION = 'subscriptions_fashion',
  PAYMENT_FASHION = 'payment_fashion',
  LAST_CHANCE_OFFER_FASHION = 'last_chance_offer_fashion',
  CANCEL_OFFER_FASHION = 'cancel_offer_fashion',
  DOWNLOAD_FASHION = 'download_fashion',
  VC_SLUG = 'vc_slug',
  NEWSLETTER_URGE = 'newsletter_urge',
}
