import { IAdminCommand, IRawAdminCommand } from 'models/common.model'

export const getCommandsFromCommandRaw = (
  commands: IRawAdminCommand[],
): IAdminCommand[] =>
  commands.map((rawCommand) => ({
    id: rawCommand.id,
    title: rawCommand.title,
    analyticsId: rawCommand.analytics_id,
    sessionDuration: rawCommand.session_duration * 60000,
  }))
