import { StripeError } from '@stripe/stripe-js'
import { TProductId, TProductName } from 'models/variant.model'
import { eventLogger } from 'services/eventLogger.service'
import { IStripeAccount } from 'models/common.model'
import { PaymentMethod, StripeErrorCode } from '../constants'

export const logFailedPayment = ({
  productId,
  productName,
  price,
  isTrialActive,
  paymentResponse: { type, code, message, decline_code: declineCode },
  paymentMethod,
  currency,
  stripeAccount,
  email,
  isRetry,
  screenName,
}: {
  productId: TProductId
  productName: TProductName
  price: number
  isTrialActive: boolean
  paymentResponse: StripeError
  paymentMethod: PaymentMethod
  currency?: string
  stripeAccount?: IStripeAccount
  email: string
  isRetry: boolean
  screenName?: string
}): void => {
  const errorCode =
    code === StripeErrorCode.CARD_DECLINED ? `${code}:${declineCode}` : code

  eventLogger.logPurchaseFailed({
    productId,
    productName,
    priceDetails: { price, trial: isTrialActive, currency },
    error: { type, code: errorCode, description: message, declineCode },
    paymentMethod,
    stripeAccount,
    email,
    isRetry,
    screenName,
  })
}
