/* eslint-disable max-lines */
import { getCurrentOnboardingVariant } from 'helpers/getCurrentOnboardingVariant'
import {
  IEvent,
  IEventLogger,
  IEventLogParams,
  TQuestionPageParams,
  TUpsellPageParams,
} from 'models/events.model'
import { TProductId, TProductName } from 'models/variant.model'
import { PaymentMethod, PaymentSystem } from 'modules/payment/constants'
import { EventLoggerInstanceName, LoginMethod } from 'root-constants'
import { IStripeAccount } from 'models/common.model'
import { getCommonEventProps } from 'helpers/getCommonEventProps'

export const enum ScreenName {
  SALES = 'offer',
  CANCEL = 'cancel_offer',
  UPSELL = 'pdf_zen_course_upsell',
  CHAT_UPSELL = 'chat_upsell',
  INVOXIA_UPSELL = 'invoxia_upsell',
}

export const enum Events {
  SESSION_STARTED = 'session_start',
  SALE_SCREEN_SHOW = 'plans_page_show', // required for Amplituda events
  ONBOARDING_PAGE_COMPLETED = 'onboarding_page_completed',
  UPSELL_PAGE_COMPLETED = 'upsell_page_completed',
  EMAIL_PAGE_SHOW = 'email_page_show',
  EMAIL_PAGE_COMPLETED = 'email_page_completed', // required for Amplituda events
  EMAIL_PAGE_ERROR = 'email_page_error',
  PAYMENT_METHOD_PAGE_SHOW = 'payment_method_page_show', // required for Amplituda events
  PURCHASE_SHOW = 'subs_purchase_show',
  PURCHASE_STARTED = 'subs_purchase_started', // required for Amplituda events
  PURCHASE_COMPLETED = 'subs_purchase_completed', // required for Amplituda events
  PURCHASE_FAILED = 'subs_purchase_failed', // required for Amplituda events
  SUBS_PURCHASE_CLOSE = 'subs_purchase_screen_close', // required for Amplituda events
  CREATE_ACCOUNT_PAGE = 'create_account_page', // required for Amplituda events
  LOGIN_METHOD_SELECTED = 'login_method_selected', // required for Amplituda events
  ACCOUNT_CREATED = 'account_created', // required for Amplituda events
  ACCOUNT_CREATION_FAILED = 'account_creation_failed', // required for Amplituda events
  FINISH_ACCOUNT_SCREEN_VIEW = 'finish_account_screen_view', // required for Amplituda events
  DOWNLOAD_BTN_PRESSED = 'download_button_press', // required for Amplituda events
  WEBAPP_BTN_PRESSED = 'webapp_redirect_button_press', // required for Amplituda events
  VIEW_PRICE = 'prices_viewed', // required for Amplituda events
  AB_SEGMENT = 'ab_segment',
  PRESS_UPSELL_TOOLTIP = 'press_upsell_tooltip',
  INAPP_PURCHASE_COMPLETED = 'inapp_purchase_completed',
  CALENDLY_SESSION_BOOKED = 'session_booked',
  PAGE_SHOWED = 'page_showed',
  SUPPORT_EMAIL_PRESSED = 'support_mail_pressed',
  SUPPORT_BTN_CLICKED = 'support_btn_clicked',
  SEGMENT = 'segment',
  EMAIL_POPUP_SHOW = 'email_popup_show',
  EMAIL_EXTRA_SCREEN_SHOW = 'email_extra_screen_show',
  EDIT_BTN_CLICKED = 'edit_btn_clicked',
}

class EventLoggerService {
  private loggers?: Map<EventLoggerInstanceName, IEventLogger>
  private eventsQueue: IEvent[] = []
  private commonEventProps: any

  init(loggers: IEventLogger[], utmTags): void {
    const entriesArr = loggers.map(
      (logger) =>
        [logger.name, logger] as [EventLoggerInstanceName, IEventLogger],
    )
    this.loggers = new Map(entriesArr)
    this.commonEventProps = getCommonEventProps(utmTags)
    this.notifyInitFinished()
  }

  addLoggers(loggers: IEventLogger[]): void {
    loggers.forEach((logger) => {
      this.loggers?.set(logger.name, logger)
    })
  }

  hasLogger(name: EventLoggerInstanceName): boolean {
    return !!this.loggers?.has(name)
  }

  logSessionStarted = ({
    optimizeVariantId,
    optimizeSegmentName,
    abVariant,
    abSegmentName,
  }: {
    optimizeVariantId: string
    optimizeSegmentName: string
    abVariant?: string
    abSegmentName?: string
  }): void => {
    const event = Events.SESSION_STARTED
    const eventProperty = {
      ab_variant: abVariant || getCurrentOnboardingVariant(optimizeVariantId),
      ab_segment_name: abVariant ? abSegmentName : optimizeSegmentName,
      ...this.commonEventProps,
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logQuestion({
    question,
    answers,
    pageNumber,
    customProperties = {},
  }: TQuestionPageParams): void {
    const event = Events.ONBOARDING_PAGE_COMPLETED
    const eventProperty = {
      page_number: pageNumber,
      question,
      ...(answers && {
        answer: !Array.isArray(answers) ? answers.split(',') : answers,
      }),
      ...this.commonEventProps,
      ...customProperties,
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logUpsellComplete({ pageNumber, productName }: TUpsellPageParams): void {
    const event = Events.UPSELL_PAGE_COMPLETED
    const eventProperty = {
      page_number: pageNumber,
      product_name: productName,
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logEditBtnClicked({ productName }: { productName: string }): void {
    const event = Events.EDIT_BTN_CLICKED
    const eventProperty = {
      product_name: productName,
      viewport_width: window.screen.width,
      viewport_height: window.screen.height,
      viewport_size: `${window.screen.width}x${window.screen.height}`,
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logCalendlySessionBooked(): void {
    const event = Events.CALENDLY_SESSION_BOOKED
    this.logEventOrPushToQueue({
      event,
      eventProperty: { ...this.commonEventProps },
    })
  }

  logSupportEmailPressed(): void {
    const event = Events.SUPPORT_EMAIL_PRESSED
    this.logEventOrPushToQueue({
      event,
      eventProperty: { ...this.commonEventProps },
    })
  }

  logSupportBtnClicked({ event }: { event: Events }): void {
    this.logEventOrPushToQueue({
      event,
      eventProperty: { ...this.commonEventProps },
    })
  }

  logPageShown({
    pageName,
    email,
    phone,
  }: {
    pageName: string
    email?: string
    phone?: string
  }): void {
    const event = Events.PAGE_SHOWED
    const eventProperty = {
      page_name: pageName,
      email,
      phone_number: phone,
      ...this.commonEventProps,
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  // Sale Page Events
  logSalePageShown({
    productIds,
    email,
    pageNumber,
    trialPrice,
    screenName,
  }: {
    productIds: string[]
    email: string
    trialPrice?: number
    pageNumber?: number
    screenName?: ScreenName
  }): void {
    const event = Events.SALE_SCREEN_SHOW
    const eventProperty = {
      product_id: productIds.join(','),
      screen_name: screenName || ScreenName.SALES,
      email,
      intro_price: trialPrice,
      ...(pageNumber !== undefined && { page_number: pageNumber }),
      ...this.commonEventProps,
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  // EmailWrapper Page Events
  logEmailPageShown(): void {
    const event = Events.EMAIL_PAGE_SHOW

    this.logEventOrPushToQueue({
      event,
      eventProperty: { ...this.commonEventProps },
    })
  }

  logEmailPageCompleted({
    email,
    emailConsent,
  }: {
    email: string
    emailConsent?: boolean
  }): void {
    const event = Events.EMAIL_PAGE_COMPLETED
    const eventProperty = {
      email,
      email_consent: emailConsent,
      ...this.commonEventProps,
    }

    this.updateAmplitudeUserProperties({ email })
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logEmailPageError(eventProperty: { error: string }): void {
    const event = Events.EMAIL_PAGE_ERROR
    this.logEventOrPushToQueue({
      event,
      eventProperty: { eventProperty, ...this.commonEventProps },
    })
  }

  // Account Page events
  logCreateAccountShown(): void {
    const event = Events.CREATE_ACCOUNT_PAGE

    this.logEventOrPushToQueue({
      event,
      eventProperty: { ...this.commonEventProps },
    })
  }

  logLoginMethodSelected({ method }: { method: LoginMethod }): void {
    const event = Events.LOGIN_METHOD_SELECTED
    const eventProperty = {
      method,
      ...this.commonEventProps,
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logAccountCreated({
    method,
    token,
    email,
  }: {
    method: LoginMethod | null
    token: string
    email: string | null
  }): void {
    const event = Events.ACCOUNT_CREATED
    const eventProperty = {
      method,
      token,
      email,
      ...this.commonEventProps,
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logAccountCreationFailed({ error }: { error: string }): void {
    const event = Events.ACCOUNT_CREATION_FAILED
    const eventProperty = {
      error_reason: error,
      ...this.commonEventProps,
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  // Getting App Page Events
  logGettingAppShown(): void {
    const event = Events.FINISH_ACCOUNT_SCREEN_VIEW

    this.logEventOrPushToQueue({
      event,
      eventProperty: { ...this.commonEventProps },
    })
  }

  logDownloadClicked({
    cb,
    productName,
  }: {
    cb?: () => void
    productName?: string
  }): void {
    const event = Events.DOWNLOAD_BTN_PRESSED
    const eventProperty = {
      ...(productName && { product_name: productName }),
      ...this.commonEventProps,
    }

    this.logEventOrPushToQueue({ event, eventProperty, cb })
  }

  logWebAppRedirectClicked({ cb }: { cb?: () => void }): void {
    const event = Events.WEBAPP_BTN_PRESSED

    this.logEventOrPushToQueue({
      event,
      eventProperty: { ...this.commonEventProps },
      cb,
    })
  }

  // Payment
  logPaymentMethodPageShown({
    email,
    screenName,
  }: {
    email: string
    screenName: string
  }): void {
    const event = Events.PAYMENT_METHOD_PAGE_SHOW
    const eventProperty = {
      screen_name: screenName,
      email,
      ...this.commonEventProps,
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logEmailPopupShown(): void {
    const event = Events.EMAIL_POPUP_SHOW
    this.logEventOrPushToQueue({
      event,
      eventProperty: { ...this.commonEventProps },
    })
  }

  logEmailExtraScreenShown(): void {
    const event = Events.EMAIL_EXTRA_SCREEN_SHOW
    this.logEventOrPushToQueue({
      event,
      eventProperty: { ...this.commonEventProps },
    })
  }

  logPaymentMethodPageClose({
    email,
    productId,
    productName,
    screenName,
  }: {
    email: string
    productId: TProductId
    productName: TProductName
    screenName: string
  }): void {
    const event = Events.SUBS_PURCHASE_CLOSE
    const eventProperty = {
      product_id: productId,
      product_name: productName,
      email,
      screen_name: screenName,
      ...this.commonEventProps,
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  // SubscriptionsWrapper Page events
  logPurchaseShown({
    productName,
    productId,
    screenName,
  }: {
    productId: TProductId
    productName: TProductName
    screenName?: ScreenName
  }): void {
    const event = Events.PURCHASE_SHOW
    const eventProperty = {
      product_id: productId,
      product_name: productName,
      screen_name: screenName || ScreenName.SALES,
      ...this.commonEventProps,
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logSubscriptionsViewPrice({
    method,
    screenName,
    email,
    scrollLvl,
  }: {
    method: string
    screenName: string
    email: string
    scrollLvl?: string
  }): void {
    const event = Events.VIEW_PRICE
    const eventProperty = {
      method,
      screen_name: screenName,
      email,
      scroll_lvl: scrollLvl,
      ...this.commonEventProps,
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logPressUpsellTooltip({ productName }: { productName: string }): void {
    const event = Events.PRESS_UPSELL_TOOLTIP
    const eventProperty = {
      product_name: productName,
      ...this.commonEventProps,
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logPurchaseStarted({
    productId,
    productName,
    priceDetails: { price, trial = false, currency = 'USD' },
    paymentMethod,
    email,
    screenName,
    stripeAccount,
  }: {
    productId: TProductId
    productName: TProductName
    priceDetails: {
      price: number
      trial?: boolean
      currency?: string
    }
    paymentMethod: PaymentMethod
    email: string
    screenName?: string
    stripeAccount?: IStripeAccount
  }): void {
    const event = Events.PURCHASE_STARTED
    const eventProperty = {
      trial,
      price,
      currency,
      email,
      product_id: productId,
      product_name: productName,
      screen_name: screenName || ScreenName.SALES,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      payment_system:
        paymentMethod === PaymentMethod.PAYPAL
          ? PaymentSystem.PAYPAL
          : PaymentSystem.STRIPE,
      ...(paymentMethod !== PaymentMethod.PAYPAL &&
        stripeAccount && {
          stripe_account_id: stripeAccount.accountId,
          stripe_account_name: stripeAccount.accountName,
        }),
      ...this.commonEventProps,
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logPurchaseCompleted({
    productId,
    productName,
    priceDetails: { price, trial = false, currency },
    paymentMethod,
    discountApplied,
    transactionId,
    email,
    screenName,
    isUpsell,
    productPrice,
    stripeAccount,
  }: {
    productId: TProductId
    productName: TProductName
    priceDetails: {
      price: number
      trial?: boolean
      currency?: string
    }
    paymentMethod?: PaymentMethod
    discountApplied?: string
    transactionId?: string
    email: string
    screenName?: string
    isUpsell?: boolean
    productPrice: string
    stripeAccount?: IStripeAccount
  }): void {
    const event = isUpsell
      ? Events.INAPP_PURCHASE_COMPLETED
      : Events.PURCHASE_COMPLETED
    const eventProperty = {
      trial,
      price,
      currency,
      email,
      product_name: productName,
      product_id: productId,
      screen_name: screenName || ScreenName.SALES,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      product_price: productPrice,
      payment_system:
        paymentMethod === PaymentMethod.PAYPAL
          ? PaymentSystem.PAYPAL
          : PaymentSystem.STRIPE,
      ...(paymentMethod !== PaymentMethod.PAYPAL &&
        stripeAccount && {
          stripe_account_id: stripeAccount.accountId,
          stripe_account_name: stripeAccount.accountName,
        }),
      ...(transactionId && { transaction_id: transactionId }),
      ...(discountApplied && { discount_applied: discountApplied }),
      ...this.commonEventProps,
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logPurchaseFailed({
    productId,
    productName,
    priceDetails: { price, trial = false, currency = 'USD' },
    error: { description, type, code, declineCode },
    paymentMethod,
    screenName,
    stripeAccount,
    email,
    isRetry,
  }: {
    productId: TProductId
    productName: TProductName
    priceDetails: {
      price: number
      trial?: boolean
      currency?: string
    }
    error: {
      type: string
      description?: string
      code?: string
      declineCode?: string
    }
    paymentMethod?: PaymentMethod
    screenName?: string
    stripeAccount?: IStripeAccount
    email: string
    isRetry: boolean
  }): void {
    const event = Events.PURCHASE_FAILED
    const eventProperty = {
      trial,
      price,
      currency,
      email,
      is_retry: isRetry,
      error_type: type,
      ...(description && { error_description: description }),
      ...(code && { error_code: code }),
      ...(declineCode && { decline_code: declineCode }),
      product_id: productId,
      product_name: productName,
      screen_name: screenName || ScreenName.SALES,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      payment_system:
        paymentMethod === PaymentMethod.PAYPAL
          ? PaymentSystem.PAYPAL
          : PaymentSystem.STRIPE,
      ...(paymentMethod !== PaymentMethod.PAYPAL &&
        stripeAccount && {
          stripe_account_id: stripeAccount.accountId,
          stripe_account_name: stripeAccount.accountName,
        }),
      ...this.commonEventProps,
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  updateAmplitudeUserProperties(options: Record<string, any>): void {
    if (
      this.loggers?.has(EventLoggerInstanceName.AMPLITUDE) &&
      this.loggers?.get(EventLoggerInstanceName.AMPLITUDE)?.updateUserProperties
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.loggers
        ?.get(EventLoggerInstanceName.AMPLITUDE)
        .updateUserProperties(options)
    }
  }

  private logEventOrPushToQueue({
    event,
    eventProperty,
    cb,
  }: IEventLogParams): void {
    if (this.loggers?.size) {
      this.logEvent({ event, eventProperty, cb })
    } else {
      this.eventsQueue.push({ event, eventProperty })
    }
  }

  private notifyInitFinished() {
    if (this.eventsQueue.length) {
      this.eventsQueue.forEach(({ event, eventProperty }) =>
        this.logEvent({ event, eventProperty }),
      )
      this.eventsQueue = []
    }
  }

  private logEvent({ event, eventProperty, cb }: IEventLogParams): void {
    this.loggers?.forEach((logger) => {
      logger.log({ event, eventProperty, cb })
    })
  }
}

export const eventLogger = new EventLoggerService()
