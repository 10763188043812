import { IAppState } from 'models/store.model'
import { ValidationText } from 'root-constants'

export const selectEmailErrorMessage = (
  state: IAppState,
): ValidationText | null => state.account.emailErrorMessage

export const selectPasswordErrorMessage = (
  state: IAppState,
): ValidationText | null => state.account.passwordErrorMessage

export const selectAccountEmail = (state: IAppState): string | null =>
  state.account.accountEmail
