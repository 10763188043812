import 'firebase/compat/auth'
import 'firebase/compat/remote-config'
import {
  IS_USER_HAS_SUBSCRIPTION,
  VC_COHORT_6_WEB,
} from 'pages/final/constants'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectUserStatus, selectVcUserStatus } from 'root-redux/selects/user'
import { cancelCalendlyEvent } from 'services/calendly.service'
import { selectCurrentVariantCohort } from 'root-redux/selects/common'

export const useDestroyBeforeUnmountComponent = (): void => {
  const userStatusDefault = useSelector(selectUserStatus)
  const userStatusVc = useSelector(selectVcUserStatus)
  const cohort = useSelector(selectCurrentVariantCohort)
  const userStatus = useMemo(
    () => (cohort === VC_COHORT_6_WEB ? userStatusVc : userStatusDefault),
    [cohort, userStatusVc, userStatusDefault],
  )

  useEffect(
    () => () => {
      const isNotPaid =
        !userStatus?.hasSubscription ||
        !localStorage.getItem(IS_USER_HAS_SUBSCRIPTION)

      window.addEventListener('beforeunload', async (event) => {
        if (isNotPaid) {
          await cancelCalendlyEvent()
        }

        event.preventDefault()
      })
    },
    [userStatus?.hasSubscription],
  )
}
