import {
  IAdminCommand,
  IAdminCommandProgress,
  TQuestionAnswers,
} from 'models/common.model'
import {
  AGE_ANSWERS_MAP,
  AnswerKey,
  COMMANDS_ANALYTICS_IDS_MAP,
} from 'root-constants'
import { t } from 'i18next'
import { getQuestionValues } from 'helpers/getQuestionValues'
import { getQuestionAnswers } from 'helpers/getQuestionAnswers'
import { getCommandsAnswers } from 'helpers/getCommandsAnswers'
import { v4 as uuidv4 } from 'uuid'

export const prepareAnswersForProfile = (
  answers: Partial<TQuestionAnswers> | null,
  timestamp: number,
  commands: IAdminCommand[],
  dogProfileId: string,
) => {
  const commandProgress: IAdminCommandProgress[] = []
  const globalAnswers = getQuestionValues(answers, [
    AnswerKey.AGE,
    AnswerKey.SHELTER,
    AnswerKey.DOG_NAME,
  ])

  const breedAnswer = getQuestionAnswers(answers, [
    AnswerKey.DOG_BREED,
    AnswerKey.BREED_KEY,
  ])

  const commandsAnswers = getCommandsAnswers(answers)

  const knownCommands =
    commands.length && commandsAnswers.length
      ? commands
          .map((adminCommand) => {
            const command = commandsAnswers.find(
              (cmd) =>
                adminCommand.analyticsId === COMMANDS_ANALYTICS_IDS_MAP[cmd],
            )

            if (!command) return null
            const commandItem = {
              command_id: adminCommand.id,
              dog_profile_id: dogProfileId,
              timestamp,
              data: {
                creation_timestamp: timestamp,
                start_timestamp: timestamp,
                state_name: 'completed',
                source_name: 'pre_added',
                required_practice_duration_in_millis:
                  adminCommand.sessionDuration,
                practice_duration_in_millis: 0,
                command_rating: 4,
              },
            }

            commandProgress.push(
              {
                id: uuidv4(),
                ...commandItem,
              },
              {
                id: uuidv4(),
                ...commandItem,
              },
            )

            return adminCommand.id
          })
          .filter((id) => id !== null)
      : []

  return globalAnswers
    ? {
        globalAnswers: {
          ...(globalAnswers?.dog_name && { name: globalAnswers?.dog_name }),
          data: {
            ...(breedAnswer?.dog_breed && {
              selected_breed_name:
                breedAnswer?.dog_breed === 'onboarding.breed.idk' ||
                breedAnswer?.dog_breed === 'onboarding.age.idk'
                  ? null
                  : t(breedAnswer?.dog_breed),
            }),
            ...(breedAnswer?.breed_key && {
              breed_key: breedAnswer?.breed_key,
            }),
            ...(globalAnswers?.dog_age &&
              AGE_ANSWERS_MAP[globalAnswers?.dog_age as string] && {
                age_group_name:
                  AGE_ANSWERS_MAP[globalAnswers?.dog_age as string],
              }),
            ...(Object.hasOwn(globalAnswers, AnswerKey.SHELTER) && {
              is_shelter_dog: globalAnswers?.shelter !== 'no',
            }),
            creation_timestamp: timestamp,
          },
          customization: {
            known_commands: knownCommands,
          },
        },
        commandProgress,
      }
    : {
        commandProgress: [],
        globalAnswers: {},
      }
}
