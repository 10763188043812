import { ApiService } from 'services/api.service'
import { PaymentApi } from './PaymentApi'
import { UserApi } from './UserApi'
import { EventsApi } from './EventsApi'
import { VariantsApi } from './VariantsApi'
import { ConfigApi } from './ConfigApi'
import { QuizApi } from './QuizApi'
import { SubscriptionsApi } from './SubscriptionsApi'
import { AdminApi } from './AdminApi'

export const baseApiService = new ApiService('/landing')
export const hooksApiService = new ApiService('/hooks')

export const paymentApi = new PaymentApi(baseApiService)
export const userApi = new UserApi(baseApiService)
export const eventsApi = new EventsApi(hooksApiService)
export const variantsApi = new VariantsApi(baseApiService)
export const configApi = new ConfigApi(baseApiService)
export const quizApi = new QuizApi(baseApiService)
export const subscriptionsApi = new SubscriptionsApi(baseApiService)
export const adminApiService = new AdminApi()
