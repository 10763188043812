import firebase from 'firebase/compat/app'

export const shouldSendEventsByRemoteConfig = ({
  remoteConfig,
  cohort,
  currentLanguage,
}: {
  remoteConfig: firebase.remoteConfig.RemoteConfig | null
  cohort: string
  currentLanguage: string
}): boolean => {
  if (!remoteConfig) return false

  const allConfig = remoteConfig.getAll()
  const cohortByLanguageKey = `${cohort}_${currentLanguage}`
  const hasCohortByLanguageValue = !!allConfig[cohortByLanguageKey]

  if (hasCohortByLanguageValue) {
    return remoteConfig.getBoolean(cohortByLanguageKey)
  }

  const hasCohortValue = !!allConfig[cohort]

  if (hasCohortValue) {
    return remoteConfig.getBoolean(cohort)
  }

  return false
}
