import React from 'react'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import { store } from 'root-redux/store'
import { App } from 'components/App'
import './styles.css'
import './i18n'
import * as Sentry from '@sentry/react'

import '@fontsource/nunito/400.css'
import '@fontsource/nunito/500.css'
import '@fontsource/nunito/600.css'
import '@fontsource/nunito/700.css'
import '@fontsource/nunito/800.css'
import '@fontsource/nunito/900.css'

import '@fontsource/noto-serif/400.css'
import '@fontsource/noto-serif/500.css'
import '@fontsource/noto-serif/600.css'
import '@fontsource/noto-serif/700.css'
import '@fontsource/noto-serif/800.css'
import '@fontsource/noto-serif/900.css'

import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/800.css'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import { getCurrentEnv } from 'helpers/getCurrentEnv'

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    // Facebook Pixel
    fbq: any
    // AppsFlyer WebSDK
    AF: any
    // ZenDesk plugin
    zE: any
    zESettings: any
    // Google analytics
    gtag: any
    MSStream: any
    // TikTok Pixel
    ttq: any
    // Snap Pixel
    snaptr: any
    // UET Tag Code
    uetq: any
    // Pinterest Tag Code
    pintrk: any
    dataLayer: any
  }
}

Sentry.init({
  dsn:
    'https://37f3f15fba40c89c8cb3dd74bb8d2ee3@o4507945771466752.ingest.us.sentry.io/4507945778282496',
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: true,
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      // disable automatic span creation
      instrumentNavigation: false,
      instrumentPageLoad: false,
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserProfilingIntegration(),
  ],
  enabled: getCurrentEnv() === 'prod',
  sampleRate: 0.01,
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 0.01,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 0.01,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^https:\/\/woofz\.academy/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.01,
} as Sentry.BrowserOptions)

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)
